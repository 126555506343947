.image-list-container {
    overflow-x: auto; /* Enable horizontal scrolling */
    scrollbar-width: thin; /* Set the width of the scroll bar */
    scrollbar-color: #888 transparent; /* Set the color of the scroll bar */
  }
  
  .image-list {
    display: flex; /* Use flex layout */
    flex-direction: row; /* Arrange items horizontally */
    padding: 10px; /* Add some padding */
  }
  
  .image-item {
    width: 200px; /* Set width for each image */
    height: auto; /* Maintain aspect ratio */
    margin-right: 10px; /* Add spacing between images */
  }
  
  /* Customize the appearance of the scroll bar */
  .image-list-container::-webkit-scrollbar {
    height: 8px; /* Set height of the horizontal scrollbar */
  }
  
  .image-list-container::-webkit-scrollbar-track {
    background: transparent; /* Set background of the scrollbar track */
  }
  
  .image-list-container::-webkit-scrollbar-thumb {
    background-color: #888; /* Set color of the scrollbar thumb */
    border-radius: 4px; /* Set border radius of the scrollbar thumb */
  }
  
  .image-list-container::-webkit-scrollbar-thumb:hover {
    background-color: #666; /* Set color of the scrollbar thumb on hover */
  }
  
  /* Hide scrollbar when there is no overflow */
  .image-list-container::-webkit-scrollbar {
    display: none;
  }
  
  /* Show scrollbar when there is overflow */
  .image-list-container:hover::-webkit-scrollbar {
    display: block;
  }
  